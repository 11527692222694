<template>
  <div class="table-wrapper" v-if="data">
    <table class="summurize-table">
      <thead>
        <tr>
          <td>Name</td>
          <td>Minimum</td>
          <td>Average</td>
          <td>Maximum</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="total in data" :key="total.name">
          <td class="res-td">
            {{ total.label }}
          </td>
          <td class="res-td">
            {{ total.value_type === 'Accumulative' ? returnMinValue(total.data) : returnMinOfMinValue(total.data) }}
            {{ total.unit.toUpperCase() }}
          </td>
          <td class="res-td">
            {{ total.value_type === 'Accumulative' ? returnAvgValue(total.data) : returnAvgOfAvgValue(total.data) }}
            {{ total.unit.toUpperCase() }}
          </td>
          <td class="res-td">
            {{ total.value_type === 'Accumulative' ? returnMaxValue(total.data) : returnMaxOfMaxValue(total.data) }}
            {{ total.unit.toUpperCase() }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    aggregation: String,
  },
  methods: {
    returnMinOfMinValue(obj) {
      let currArr = this.deepCopy(obj.minArr)
      let minValue = Math.min(...currArr)
      return minValue.toLocaleString('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    },
    returnAvgOfAvgValue(obj) {
      let currArr = this.deepCopy(obj.avgData)
      if (currArr.length === 0) return 0
      const sum = currArr.reduce((a, b) => a + b)
      return Math.floor(sum / currArr.length).toLocaleString('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    },
    returnMinValue(arr) {
      let currArr = this.deepCopy(arr)
      if (currArr.length === 0) return 0
      let minValue = Math.min(...currArr)
      return minValue.toLocaleString('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    },
    returnMaxOfMaxValue(obj) {
      let currArr = this.deepCopy(obj.maxArr)
      let maxValue = Math.max(...currArr)
      return maxValue.toLocaleString('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    },
    returnMaxValue(arr) {
      let currArr = this.deepCopy(arr)
      if (currArr.length === 0) return 0
      let maxValue = Math.max(...currArr)
      return maxValue.toLocaleString('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    },
    returnAvgValue(arr) {
      let currArr = this.deepCopy(arr)
      if (currArr.length === 0) return 0
      const sum = currArr.reduce((a, b) => a + b)
      return Math.floor(sum / currArr.length).toLocaleString('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    },
    deepCopy(proxy) {
      return JSON.parse(JSON.stringify(proxy))
    },
  },
}
</script>
