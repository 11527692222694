<template>
  <div class="analytics-page" id="analyticsPageId">
    <span v-if="!checkWidth" @click="toggleFilter" class="sidebar-arrow-right">
      <!-- <i class="pi pi-angle-right" style="font-size: 2rem"></i> -->
      <i class="pi pi-angle-right" style="font-size: 2rem"></i>
    </span>
    <div :class="this.checkWidth ? 'sidebar-view' : 'sidebar-view-smallScreen'">
      <!-- v-show="checkWidth || (!checkWidth && menuExpend)" -->
      <span v-if="!checkWidth" class="sidebar-arrow-left" @click="toggleFilter">
        <i class="pi pi-angle-left" style="font-size: 2rem"></i>
      </span>
      <p class="analytics-sidebar-header">Analytics</p>
      <section class="analytics-sidebar-sub-header">
        <p style="">Filter</p>
        <hr />
      </section>
      <AnalyticsFilter
        ref="filter"
        @filterChanged="getDataForFilter"
        :isPublic="isPublic"
        :Project="Project"
        :ProjectType="ProjectType"
        :Country="Country"
        :Donors="Donors"
      />
    </div>
    <div class="data-streams-container">
      <div v-if="chartsData.length > 0">
        <section
          v-for="(chart, idx) in chartsData"
          :key="idx"
          class="chart-data-wrapper"
        >
          <div v-if="chart?.title" class="chart-data-title">
            <a :href="chart.title.link" target="_blank">{{
              chart.title.text
            }}</a>
            <div class="monitoring-population" v-if="chart?.title?.population">
              <img :src="$peopleToImpact" /> &nbsp;
              {{ chart.title.population.toLocaleString('en') }}
            </div>
          </div>
          <section v-if="chart?.data.datasets" class="total-values-wrapper">
            <AnalyticsTotalCards
              :data="chart.data.datasets"
              :bigScreen="checkWidth"
            />
          </section>
          <section class="chart-container">
            <AnalyticsChart
              :options="chart.config"
              :data="chart.data"
              :idx="idx"
              :title="chart?.title?.text || 'Data Chart'"
            />
          </section>
          <section class="summurize-section">
            <AnalyticsTable
              :data="chart.tabelsData"
              :aggregation="chart.aggregation"
            />
          </section>
          <hr
            v-if="chartsData.length > 1 && idx <= chartsData.length - 1"
            style="color: #810000"
          />
        </section>
      </div>
    </div>
    <div
      v-if="loading"
      style="
        z-index: 49;
        height: 100%;
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        background: rgb(211 211 211 / 50%);
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <img :src="$loadingGIF" alt="" />
    </div>
  </div>
</template>

<script>
import AnalyticsFilter from './components/AnalyticsFilter.vue'
import AnalyticsTotalCards from './components/AnalyticsTotalCards.vue'
import AnalyticsChart from './components/AnalyticsChart.vue'
import AnalyticsTable from './components/AnalyticsTable.vue'
import { ElNotification } from 'element-plus'
import axios from 'axios'
import moment from 'moment'

export default {
  props: {
    isPublic: Boolean,
    ProjectType: Array,
    Country: Array,
    Project: Array,
    Donors: {
      type: Array,
      required: false,
      default: [],
    },
  },
  components: {
    AnalyticsFilter,
    AnalyticsTotalCards,
    AnalyticsChart,
    AnalyticsTable,
  },
  data() {
    return {
      chartsData: [],
      emptyChart: {
        config: analyticsConfig,
        data: {
          labels: [],
          datasets: [],
        },
      },
      windowWidth: window.innerWidth,
      menuExpend: false,
      loading: false,
      numberOfProjectsData: 0,
    }
  },
  async mounted() {
    let mainHeaderHeight = $('#mainHeader').innerHeight()
    $('#analyticsPageId').css('height', `calc(100vh - ${mainHeaderHeight}px)`)

    window.addEventListener('resize', this.onResize)
    this.loading = true
    this.onResize()
    this.config = analyticsConfig
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.size > 0) {
    } else {
      this.getDataForFilter()
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    checkWidth() {
      return this.windowWidth > 991 ? true : false
    },
  },
  methods: {
    async getDataForFilter() {
      this.loading = true
      if (!this.checkWidth && this.menuExpend) {
        this.toggleFilter()
      }
      let filterBy = this.$refs.filter.getFilterBy()
      const isOneChart = !filterBy.many
      let data = isOneChart
        ? await this.getDataForOneMainChart(filterBy)
        : await this.getDataForManyCharts(filterBy)
      data = isOneChart ? data : data.filter((obj) => obj !== undefined)

      if ((isOneChart && data) || (!isOneChart && data && data?.length > 0)) {
        this.chartsData = isOneChart ? [data] : data
      } else {
        this.showNotifications('error', 'No Data for Current Filter')
        this.chartsData = [this.emptyChart]
      }

      this.loading = false
      this.onResize()
    },
    async getDataForOneMainChart(filterBy = null) {
      try {
        let projectsToRender
        if (
          filterBy.projectId.length === 0 &&
          filterBy.projectDonor.length > 0
        ) {
          projectsToRender = this.$refs.filter
            .getAvailableProjects()
            .filter((project) => {
              const projectDonorIds =
                project?.donors?.map((donor) => donor.id) || []
              return projectDonorIds.some((donorId) =>
                filterBy.projectDonor.includes(donorId)
              )
            })
          filterBy.projectId = projectsToRender
        }

        let configForCurrChart = JSON.parse(JSON.stringify(this.config))
        const params = this.createdParams(filterBy)
        console.log(params)
        let projectCountingFetchData
        let data = await axios.post(
          '/api/analytics/monitorings',
          {
            ...params,
            function: 'count(measurement_points.project_id) AS nb_projects',
          }
        )
        if (!this.isPublic) projectCountingFetchData = data
        let dataForMainChart = data.data
        let { labels, formmatedLabels } = this.getLabels(params.aggregation)
        let { dataSetsFromDB } = organizeDatasets(
          dataForMainChart,
          labels,
          projectCountingFetchData?.data
        )
        if (dataSetsFromDB.length === 0) return

        let flattenDatasets = dataSetsFromDB
          .flat()
          .filter((set) => set?.data?.length > 0)
        if (flattenDatasets.length === 0) return

        const tempScales = removeDuplicatesScales(
          flattenDatasets.map(({ group, unit }) => ({ group, unit }))
        )
        const currentScales = createScaleObject(tempScales)

        const dataForTabels = dataForMainChart
          .map((data) => getDataSetForAccumulative(data, labels))
          .filter((obj) => obj.data.length !== 0)

        configForCurrChart.scales = currentScales
        return {
          data: {
            labels: formmatedLabels,
            datasets: flattenDatasets,
          },
          config: configForCurrChart,
          tabelsData: dataForTabels,
          aggregation: params.aggregation,
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getDataForManyCharts(filterBy = null) {
      let projectsToRender = this.$refs.filter.getAvailableProjects()
      if (filterBy.projectId.length > 0) {
        const idsArr = filterBy.projectId.map((p) => p.id)
        projectsToRender = projectsToRender.filter(({ id }) =>
          idsArr.includes(id)
        )
        if (projectsToRender.length === 0) return
      }
      const filterToMoveOn = { ...filterBy, projects: projectsToRender }
      const params = this.createdParams(filterToMoveOn)
      let fetch = await axios.post('/api/analytics/monitorings', params)
      let dataForCharts = JSON.parse(JSON.stringify(fetch.data))
      const projectsData = projectsToRender.map((p) => {
        const projectData = this.organizeProjectDataForChart(
          p,
          dataForCharts,
          filterBy.aggregation
        )
        return projectData
          ? projectData
          : {
              data: {
                labels: [],
                datasets: [],
              },
              config: analyticsConfig,
              aggregation: 'Daily',
              title: {
                text: p.name,
                population: p.population,
                link: p.donor_page_link,
              },
            }
      })
      return projectsData
    },
    organizeProjectDataForChart(project, data, aggregation) {
      let configForCurrChart = JSON.parse(JSON.stringify(this.config))
      let onlyCurrentProjectData = data
        .map((obj) => {
          const onlyCurrProjectValuesArr = obj.data.filter(
            (d) => d.project_id === (project.id || project)
          )
          return onlyCurrProjectValuesArr.length > 0
            ? { ...obj, data: onlyCurrProjectValuesArr }
            : undefined
        })
        .filter((obj) => obj !== undefined)

      if (onlyCurrentProjectData.length === 0) return
      let { labels, formmatedLabels } = this.getLabels(aggregation)
      let { dataSetsFromDB } = organizeDatasets(onlyCurrentProjectData, labels)
      if (dataSetsFromDB.length === 0) return

      let flattenDatasets = dataSetsFromDB
        .flat()
        .filter((set) => set?.data?.length > 0)
      if (flattenDatasets.length === 0) return

      const tempScales = removeDuplicatesScales(
        flattenDatasets.map(({ group, unit }) => ({ group, unit }))
      )
      const currentScales = createScaleObject(tempScales)
      const dataForTabels = onlyCurrentProjectData
        .map((data) => getDataSetForAccumulative(data, labels))
        .filter((obj) => obj.data.length !== 0)

      configForCurrChart.scales = currentScales
      return {
        data: {
          labels: formmatedLabels,
          datasets: flattenDatasets,
        },
        config: configForCurrChart,
        tabelsData: dataForTabels,
        title: {
          text: project.name,
          population: project.population,
          link: project.donor_page_link,
        },
        aggregation: aggregation,
      }
    },
    createdParams(filterBy) {
      console.log(filterBy)
      let params = {
        query: {},
        aggregation: filterBy.aggregation,
        ts_from: filterBy.date[0],
        ts_to: filterBy.date[1],
        value_types: [],
      }
      if (
        this.isPublic ||
        !(filterBy?.projectId?.length === 1 || filterBy?.many)
      ) {
        params['value_types'] = ['Accumulative']
      }

      if (filterBy?.projectType?.length > 0) {
        params.query['project_type_id_in'] = filterBy.projectType
      }
      if (filterBy?.projectCountry?.length > 0) {
        params.query['country_id_in'] = filterBy.projectCountry
      }
      if (filterBy?.projectId?.length > 0) {
        params.query['id_in'] = filterBy.projectId.map((proj) => {
          if (proj.id) {
            return proj.id
          } else {
            return proj
          }
        })
      }

      if (filterBy?.many) {
        params['group_by'] = 'project_id'
      }
      if (typeof params.ts_from !== 'string') {
        params.ts_from = params.ts_from.format('YYYY-MM-DD')
      }
      if (typeof params.ts_to !== 'string') {
        params.ts_to = params.ts_to.add(1, 'days').format('YYYY-MM-DD')
      }

      if (filterBy?.year?.length > 0) {
        console.log(filterBy.year)
        params.query['completion_year_in'] = filterBy.year
      }
      return params
    },
    getLabels(aggregation) {
      let filterBy = this.$refs.filter?.getFilterBy()
      let labels
      let formmatedLabels
      if (aggregation === 'Hourly') {
        labels = getHoursBetweenDates(filterBy.date)
        formmatedLabels = labels.map((label) => {
          const tempDate = new Date(label)
          const date = tempDate.toLocaleString('en-US', {
            timeZone: 'UTC',
          })
          return formatDateHourly(date)
        })
      } else if (aggregation === 'Monthly') {
        labels = getMonthsBetweenDates(filterBy.date)
        formmatedLabels = labels.map((label) => {
          const date = new Date(label)
          return date.toLocaleString('default', { month: 'short' })
        })
      } else {
        labels = getDatesBetween(filterBy.date)
        formmatedLabels = labels.map((label) =>
          moment(label).format('YYYY-MM-DD')
        )
        formmatedLabels = labels.map((label) => this.formatDate(label))
      }
      return {
        labels,
        formmatedLabels,
      }
    },
    formatDate(dateStr) {
      const date = new Date(dateStr)
      const day = date.getDate()
      const month = date.toLocaleString('default', { month: 'short' })
      return `${day}. ${month}`
    },
    toggleFilter() {
      this.menuExpend = !this.menuExpend
      if (this.menuExpend) {
        $('.sidebar-view-smallScreen').css('left', `0%`)
        setTimeout(() => {
          this.$nextTick(() => {
            $('.data-streams-container').css('overflow', `hidden`)
          })
        }, 500)
      } else {
        $('.data-streams-container').css('height', `unset`)
        $('.data-streams-container').css('overflow', `unset`)
        $('.sidebar-view-smallScreen').css('left', `-100%`)
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth

      let mainHeaderHeight = $('#mainHeader').innerHeight()

      const windowHeight = $(window).height()

      $('#analyticsPageId').css(
        'height',
        `calc(${windowHeight}px - ${mainHeaderHeight}px)`
      )

      if (this.windowWidth > 991) {
        $('.data-streams-container').css('overflow-y', `auto`)
        $('.data-streams-container').css('height', `unset`)
      } else {
        $('.data-streams-container').css('overflow', `unset`)
        $('.sidebar-view-smallScreen').css('left', `-100%`)
      }
    },
    showNotifications(type, title) {
      ElNotification({
        title,
        type,
      })
    },
  },
}
</script>