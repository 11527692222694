<template>
  <div
    v-for="(total, index) in data.filter(
      (data) => data?.value_type === 'Accumulative'
    )"
    :key="total?.name"
    class="total-value-container"
  >
    <span>Total {{ total.label }}</span>
    <div>
      {{ calcTotal(total.data, total.unit) }}
    </div>
  </div>
</template>

<script>
import { getTotalOfDataAndUnit } from '../../../utills/getTotalOfDataAndUnit'
export default {
  props: {
    data: Object,
    bigScreen: Boolean,
  },
  // box-shadow: 0 1px 15px 0 hsl(0deg 0% 48% / 10%);
  //   border-radius: 25px;
  methods: {
    getStyle(index) {
      // if (index !== 0 && (this.bigScreen || window.innerWidth > 600)) {
      if (
        (index !== 0 && this.bigScreen) ||
        (index !== 0 && window.innerWidth > 600)
      ) {
        return {
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'flex-end',
          borderLeft: '1px solid #810000',
        }
      }
    },
    calcTotal(data, unit) {
      return getTotalOfDataAndUnit(data, unit)
    },
  },
}
</script>
