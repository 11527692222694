<template>
  <div class="sidebar-content">
    <div>
      <strong>Aggregation</strong>
      <el-select
        placeholder="Aggregation"
        v-model="filterBy.aggregation"
        @change="updateProjectsNames"
      >
        <el-option
          v-for="aggregation in getAggregations()"
          :key="aggregation"
          :label="aggregation"
          :value="aggregation"
        />
      </el-select>
    </div>

    <div>
      <strong>Period</strong>
      <el-select v-model="selectedOption" placeholder="Select Period">
        <el-option
          v-for="option in ranges"
          :key="option.value"
          :value="option.title"
          @click="handleClick(option)"
        >
          {{ option.title }}
        </el-option>
      </el-select>
    </div>

    <div v-if="selectedOption === 'Custom Date'">
      <div>
        <strong>Start Date:</strong>
        <el-date-picker
          @change="handleChangeStartDate"
          v-model="startDate"
          type="date"
          placeholder="Pick a day"
          size="small"
          :disabled-date="checkDateStartDate"
        />
        <strong></strong>
      </div>
      <div>
        <strong>End Date:</strong>
        <el-date-picker
          @change="handleChangeEndDate"
          v-model="endDate"
          type="date"
          placeholder="Pick a day"
          size="small"
          :disabled-date="checkDateEndDate"
        />
      </div>
      <strong class="selected-dates-wrapper"
        >{{ startDate ? startDate : 'No Date Selected' }} -
        {{ endDate ? endDate : 'No Date Selected' }}</strong
      >
    </div>

    <div v-if="countries.length > 0">
      <strong>Country</strong>
      <el-select
        v-model="filterBy.projectCountry"
        placeholder="All Countries"
        multiple
        collapse-tags
        @change="updateProjectsNames"
      >
        <el-option
          v-for="country in countries"
          :key="country.id"
          :label="country.name"
          :value="country.id"
        />
      </el-select>
    </div>

    <div v-if="projectTypes.length > 0">
      <strong>Project Type</strong>
      <el-select
        placeholder="All Project Types"
        v-model="filterBy.projectType"
        multiple
        collapse-tags
        @change="updateProjectsNames"
      >
        <el-option
          v-for="projectType in projectTypes"
          :key="projectType.id"
          :label="projectType.name"
          :value="projectType.id"
        />
      </el-select>
    </div>

    <div v-if="projectsObjects">
      <strong>Project Name</strong>
      <el-select
        v-model="filterBy.projectId"
        placeholder="Select a Project Name"
        size="default"
        filterable
        multiple
        collapse-tags
      >
        <el-option
          v-for="project in projectsObjects.sort((a, b) => a.name.localeCompare(b.name))"
          :key="project.id"
          :label="project.name"
          :value="project.id"
        />
      </el-select>
      <button
          v-if="!isPublic"
          class="toggle-input-list-btn"
          @click.stop="showProjectsNamesInput = !showProjectsNamesInput">
          Enter a list of project names
        </button>
        <div v-if="showProjectsNamesInput" class="list-html-input-wrapper">
          <div class="html-input-wrapper">
            <textarea v-model="checkHtml" class="html-input"></textarea>
            <button @click.stop="processHTML">Process List</button>
          </div>
        </div>
    </div>

    <div v-if="donorsDB && !isPublic">
      <strong>Donor Name</strong>
      <el-select
        @change="updateProjectsNames"
        v-model="filterBy.projectDonor"
        placeholder="Select a Donor"
        size="default"
        filterable
        multiple
        collapse-tags
      >
        <el-option
          v-for="donor in donorsDB"
          :key="donor.id"
          :label="donor.name"
          :value="donor.id"
        />
      </el-select>
    </div>

    <div v-if="years && !isPublic">
      <strong>Project Year</strong>
      <el-select
        v-model="filterBy.year"
        placeholder="Select a Year"
        size="default"
        filterable
        multiple
        collapse-tags
      >
        <el-option
          v-for="year in years"
          :key="year.id"
          :label="year.id"
          :value="year.id"
        />
      </el-select>
    </div>

    <div v-if="!isPublic">
      <el-switch
        v-model="filterBy.many"
        active-text="Chart per Project"
        inactive-text="Single Chart"
      />
    </div>

    <button
      @click="handleSubmit"
      class="update-filter-btn"
    >
      <span>Apply</span>
      <img :src="$buttonSvg" alt="" />
    </button>
  </div>
</template>

<script>
import moment from 'moment'
import { ElNotification } from 'element-plus'

export default {
  props: {
    isPublic: Boolean,
    ProjectType: Array,
    Country: Array,
    Project: Array,
    Donors: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      aggregations: ['Daily', 'Monthly', 'Hourly'],
      countries: [],
      projectTypes: [],
      filterBy: {
        projectCountry: [],
        projectType: [],
        projectId: [],
        date: [moment().subtract(29, 'days'), moment()],
        aggregation: 'Daily',
        many: false,
        projectDonor: [],
        year: [],
      },
      configDate: {
        mode: 'range',
      },
      ranges: [
        {
          title: 'Today',
          value: [moment(), moment()],
        },
        {
          title: 'Yesterday',
          value: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        },
        {
          title: 'Last 7 Days',
          value: [moment().subtract(6, 'days'), moment()],
        },
        {
          title: 'Last 30 Days',
          value: [moment().subtract(29, 'days'), moment()],
        },
        {
          title: 'This Month',
          value: [moment().startOf('month'), moment().endOf('month')],
        },
        {
          title: 'Last Month',
          value: [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
          ],
        },
        { title: 'Custom Date', value: 'Custom Date' },
      ],
      projectsDB: null,
      donorsDB: null,
      projectsObjects: null,
      dateRange: [],
      userChoice: 'Last 30 Days',
      selectedOption: 'Last 30 Days',
      startDate: null,
      endDate: null,
      startDateForBE: null,
      endDateForBE: null,
      filterToEmit: {
        projectCountry: [],
        projectType: [],
        projectId: [],
        date: [moment().subtract(29, 'days'), moment()],
        aggregation: 'Daily',
        many: false,
        projectDonor: [],
        year: [],
      },
      showProjectsNamesInput: false,
      checkHtml: '',
      years: []
    }
  },
  async created() {
    for (let i = 2008; i <= new Date().getFullYear(); i++)
      this.years.push({ id: i, name: i.toString() });

    console.log(this.years)

    this.projectTypes = deepCopy(this.ProjectType)
    this.countries = deepCopy(this.Country)
    this.projectsDB = deepCopy(this.Project)
    this.projectsObjects = this.projectsDB.map((project) => {
      return {
        name: project.name,
        id: project.id,
        donor_page_link: project.donor_page,
        project_type_id: project.project_type_id,
        country_id: project.country_id,
        population: project.population,
        donors_id: project?.donors_id || [],
      }
    })

    if (!this.isPublic) {
      this.projectsObjects = this.organizeProjectsObjects(
        this.projectsObjects,
        this.Donors,
      )
      this.donorsDB = this.organizeDonors()
    }
    this.projectsDB = this.projectsObjects
    this.$emit(
      'projectsLoaded',
      JSON.parse(JSON.stringify(this.projectsObjects))
    )

    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.size > 0) this.handleFilterFromParams(searchParams)
  },
  methods: {
    processHTML() {
      let arr = this.checkHtml
        .split('\n')
        .map((item) => this.decodeHtmlEntities(item))
      arr = this.Project
        .filter((obj) => arr.includes(obj.name))
        .map((item) => item.id)
        console.log(arr)
      if (this.filterBy.projectId?.length > 0) {
        arr = arr.filter((id) => {
          if (!this.filterBy.projectId.includes(id)) return id
        })
      }
      const newArray = [...this.filterBy.projectId]
      newArray.push(...arr)
      this.filterBy.projectId = newArray
      this.showProjectsNamesInput = false
      this.checkHtml = ''
    },
    decodeHtmlEntities(input) {
      const entities = {
        '&amp;': '&'
      }
      for (const entity in entities) {
        if (entities.hasOwnProperty(entity)) {
          const replacement = entities[entity]
          input = input.replace(new RegExp(entity, 'g'), replacement)
        }
      }
      return input
    },
    getFilterBy() {
      return this.filterToEmit
    },
    getAvailableProjects() {
      return this.projectsObjects
    },
    handleFilterFromParams(searchParams) {
      let paramsDataFilterObject = deepCopy(this.filterBy)
      for (const param of searchParams) {
        if (param[0] === 'many') {
          paramsDataFilterObject[param[0]] = param[1] === 'true' ? true : false
        } else if (param[0] === 'aggregation') {
          paramsDataFilterObject[param[0]] = param[1]
        } else {
          const arr = param[1]
            .split(',')
            .map((item) => (isNaN(item) ? item.trim() : +item))
          paramsDataFilterObject[param[0]] = arr
        }
      }
      this.filterBy = paramsDataFilterObject
      this.handleSubmit()
      this.updateProjectsNames()
    },
    checkDateEndDate(e) {
      const receivedDate = new Date(this.startDate)
      const today = new Date()
      if (e > today || e < receivedDate) {
        return true
      } else {
        return false
      }
    },
    checkDateStartDate(e) {
      let receivedDate = null
      if (this.endDate) {
        receivedDate = new Date(this.endDate)
      }
      const today = new Date()
      if (e > today || (receivedDate && e > receivedDate)) {
        return true
      } else {
        return false
      }
    },
    handleChangeStartDate(e) {
      if (!e) {
        this.startDate = null
        this.startDateForBE = null
        return
      }
      let a = moment(e)
      let dateForUser = a.format('YYYY-MM-DD')
      this.startDate = formatDate(dateForUser)
      this.startDateForBE = dateForUser
      // this.filterBy.date.startDate = a.format('YYYY-MM-DD')
    },
    handleChangeEndDate(e) {
      if (!e) {
        this.endDate = null
        this.endDateForBE = null
        return
      }
      let a = moment(e)
      let dateForUser = a.format('YYYY-MM-DD')
      this.endDate = formatDate(dateForUser)
      a = a.add(1, 'days')
      this.endDateForBE = a.format('YYYY-MM-DD')
      // this.filterBy.date.endDate = a.format('YYYY-MM-DD')
    },
    handleClick(range) {
      let data = JSON.parse(JSON.stringify(range))
      this.filterBy.date = data.value
    },
    updateProjectsNames() {
      let markersForMap = JSON.parse(JSON.stringify(this.projectsDB))
      let currFilter = JSON.parse(JSON.stringify(this.filterBy))
      let filteredMarkers = markersForMap.filter((marker) => {
        let include = true
        if (currFilter.projectCountry?.length) {
          include = currFilter.projectCountry.includes(marker.country_id)
        }
        if (include && currFilter.projectType?.length) {
          include = currFilter.projectType.includes(marker.project_type_id)
        }
        if (include && currFilter.projectDonor?.length) {
          let includeDonor = false
          for (let i = 0; i < marker?.donors?.length; i++) {
            if (currFilter.projectDonor.includes(marker.donors[i].id)) {
              includeDonor = true
              break
            }
          }
          include = includeDonor
        }
        if (include === true) {
          return marker
        }
      })
      this.projectsObjects = filteredMarkers.map((project) => {
        return {
          name: project.name,
          id: project.id,
          donor_page_link: project.donor_page_link,
          donors: project.donors,
          project_type_id: project.project_type_id,
          country_id: project.country_id,
          population: project.population,
        }
      })
      return filteredMarkers
    },
    getAggregations() {
      return this.isPublic
        ? ['Daily', 'Monthly']
        : ['Hourly', 'Daily', 'Monthly']
    },
    organizeProjectsObjects(projects, donorsArr) {
      return projects.map((project) => {
        let donors = this.getDonors(project.donors_id, donorsArr)
        return {
          name: project.name,
          id: project.id,
          donor_page_link: project.donor_page_link,
          donors: donors,
          project_type_id: project.project_type_id,
          country_id: project.country_id,
          population: project.population,
        }
      })
    },
    getDonors(projectDonors, donorsArr) {
      return donorsArr.filter((donor) => projectDonors.includes(donor.id))
    },
    organizeDonors() {
      let donors = this.projectsObjects.map((project) => project.donors)
      let arr = donors.flat(Infinity)
      const seen = new Set()
      let newDonorsArr = arr.filter((obj) => {
        return !seen.has(obj.id) && seen.add(obj.id)
      })
      let x = newDonorsArr.sort(function (a, b) {
        let nameA = a.name.toUpperCase()
        let nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
      return x
    },
    handleSubmit() {
      let datesArr = []
      let porjectsObjs = []

      if (this.filterBy.projectId?.length > 0 && this.filterBy.many) {
        let idArr = JSON.parse(JSON.stringify(this.filterBy.projectId))
        porjectsObjs = this.projectsObjects.filter((project) =>
          idArr.includes(project.id)
        )
      }

      if (this.selectedOption === 'Custom Date') {
        if (!this.startDateForBE || !this.endDateForBE) {
          ElNotification({
            title: 'You must select a start and end date',
            type: 'error',
          })
          return
        }
        this.filterBy.date = [this.startDateForBE, this.endDateForBE]
      } else {
        for (let i = 0; i < this.filterBy.date.length; i++) {
          let currDate = JSON.parse(JSON.stringify(this.filterBy.date[i]))
          if (currDate.length === 10) {
            datesArr.push(currDate)
          } else {
            let a = moment(currDate)
            if (i === 1) {
              a = a.add(1, 'days')
            }
            datesArr.push(a.format('YYYY-MM-DD'))
          }
        }
        this.filterBy.date = datesArr
      }

      let filterToEmit = JSON.parse(JSON.stringify(this.filterBy))

      if (porjectsObjs?.length > 0) {
        filterToEmit.projectId = porjectsObjs
      }
      if (
        filterToEmit?.projectId?.length === 0 &&
        filterToEmit?.projectDonor?.length > 0
      ) {
        let x = this.projectsDB.filter((project) => {
          if (project.donors.length > 0) {
            for (let i = 0; i < project.donors.length; i++) {
              if (filterToEmit.projectDonor.includes(project.donors[i].id)) {
                return project
              }
            }
          }
        })
        filterToEmit.projectId = x
      }
      this.filterToEmit = filterToEmit
      this.$emit('filterChanged', JSON.parse(JSON.stringify(filterToEmit)))
    },
  },
  computed: {
    maxDate() {
      return new Date()
    },
  },
}
</script>

<style lang="scss">
.el-switch.mb-2 {
  text-align: center;
}

.el-input.el-input--small.el-input--prefix.el-input--suffix.el-date-editor.el-date-editor--date.el-tooltip__trigger.el-tooltip__trigger {
  width: 100% !important;
}

.el-input__wrapper {
  border-radius: 20px !important;
}

.el-select__wrapper {
  border-radius: 20px !important;
}

.toggle-input-list-btn {
        background-color: #f4f4f5;
        border: 1px solid #b6b6b6;
        margin-bottom: 6px;
        font-size: 13px;
        width: 100%;
        cursor: pointer;
        border-radius: 20px;
        margin-top: 6px;
        &:hover {
          background-color: #e3e3e3;
        }
      }

      .list-html-input-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;

        .html-input-wrapper {
          display: flex;
          flex-direction: column;
          gap: 6px;
          width: 100%;
          .html-input {
            border: 1px solid #ccc;
            border-radius: 20px;
            padding-inline: 11px;
            font-size: 14px;
            background-color: white;
            line-height: 1.6;
            outline: none;
            white-space: pre-wrap;
            overflow-wrap: break-word;
            word-wrap: break-word;

            &:focus {
              border-color: #3498db;
            }
          }
        }

        [contenteditable][data-placeholder]:empty:before {
          content: attr(data-placeholder);
          color: #bababa;
        }

        button {
          background-color: #f4f4f5;
          border: 1px solid #b6b6b6;
          margin-bottom: 6px;
          font-size: 13px;
          border-radius: 20px;
          cursor: pointer;
          &:hover {
            background-color: #e3e3e3;
          }
        }
      }
</style>
