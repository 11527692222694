import { createApp } from 'vue';
import App from '../components/analytics/Analytics.vue';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '../components/analytics/stylesheets/analytics.scss';
import PrimeVue from 'primevue/config'
window.monitorings = function monitorings(divId, props=null) {
  const app =  createApp(App, props).use(ElementPlus).use(PrimeVue)
  addAssets(app);
  app.mount(`#${divId}`)
};
